<template>
  <v-app id="register-verify" class="security">
    <v-main>
      <v-container fluid fill-height>
        <v-row>
          <v-col cols="12">
            <v-form autocomplete="off" @submit.prevent="verify">
              <v-card
                max-width="800"
                elevation="0"
                class="mx-auto py-0"
                rounded="0"
                ><v-row no-gutters class="shadow-one">
                  <v-col
                    cols="12"
                    sm="6"
                    v-if="$vuetify.breakpoint.mdAndUp"
                    class="third d-flex"
                  >
                    <v-card
                      elevation="0"
                      class="py-0 mx-auto my-auto"
                      rounded="0"
                      max-width="400"
                      color="rgba(255,255,255,0)"
                    >
                      <v-img
                        width="230"
                        :src="require('@/assets/img/register-confirm.png')"
                      ></v-img>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="12"
                    class="white"
                    :sm="$vuetify.breakpoint.mdAndUp ? '6' : '12'"
                  >
                    <v-card
                      elevation="0"
                      class="pt-4 mx-auto mb-5"
                      rounded="0"
                      max-width="400"
                    >
                      <v-card-text class="mb-0 pb-2 px-8 text-center">
                        <div class="d-flex justify-center mt-8">
                          <div class="mb-5">
                            <v-img
                              :src="require('@/assets/img/logo.png')"
                              width="60"
                            ></v-img>
                          </div>
                        </div>
                        <div class="text-h6 primary--text">
                          <span class="font-weight-bold">{{
                            $t("security.register.verify.title")
                          }}</span>
                        </div>
                        <div class="text-body-2 grey--text">
                          {{ $t("security.register.verify.description") }}
                        </div>
                      </v-card-text>
                      <v-card-text class="mt-2 mb-1 pb-0 px-8">
                        <div class="pb-4">
                          <span class="text-subtitle-2 font-weight-medium">{{
                            $t("security.register.verify.help")
                          }}</span>
                        </div>
                        <v-text-field
                          append-icon="mdi-shield-key-outline"
                          name="email"
                          v-model="form.otp"
                          :label="`${$t('security.fields.otp.title')} *`"
                          autocomplete="off"
                          outlined
                          dense
                          maxlength="6"
                          @input="delayTouch($v.form.otp)"
                          @blur="$v.form.otp.$touch"
                          :error-messages="otpErrors"
                          class="font-weight-bold"
                        ></v-text-field>
                      </v-card-text>
                      <v-card-actions class="px-8 pt-0 mt-0">
                        <v-btn
                          color="primary"
                          block
                          type="submit"
                          :loading="loading.verify"
                          :disabled="$v.form.$invalid"
                          >{{ $t("btn.validate") }}</v-btn
                        >
                      </v-card-actions>
                      <v-card-text class="text-center">
                        <div class="grey--text">
                          {{ $t("security.btn.resend_otp.description") }}
                        </div>
                        <v-btn
                          text
                          color="primary"
                          @click="resendOtp()"
                          :loading="loading.resend"
                          class="text-subtitle-2"
                          ><span class="secondary-font">{{
                            $t("security.btn.resend_otp.title")
                          }}</span></v-btn
                        >
                      </v-card-text>
                      <v-card-actions class="mb-2 pb-0 mt-8 px-2">
                        <v-spacer></v-spacer>
                        <lang :btnOptions="{ 'x-small': true }"></lang>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
              <copyright></copyright>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Lang from "./../commons/lang";
import { mapActions } from "vuex";
import { required, minLength, helpers } from "vuelidate/lib/validators";
import FormDelayTouchMixin from "./../../mixins/form-delay-touch";
import Copyright from "./coypright";

export default {
  mixins: [FormDelayTouchMixin],
  data: () => ({
    form: {
      otp: null,
    },
    loading: {
      verify: false,
      resend: false,
    },
  }),
  metaInfo() {
    return {
      title: this.$t("security.register.verify.title"),
    };
  },
  validations: {
    form: {
      otp: {
        required,
        minLength: minLength(6),
        matchOtp: helpers.regex("otp", /^[0-9]{6}$/),
      },
    },
  },
  methods: {
    async verify() {
      const sessionRedirectPath = sessionStorage.getItem("_redirect_path");
      let message = "security.fields.otp.invalid";

      this.notify({ status: false });

      if (this.$v.form.$invalid) {
        this.notify({
          message: this.$t(message),
          centered: true,
          right: false,
        });
        return;
      }

      this.loading.verify = true;
      try {
        await this.request({
          url: "/api/auth/register-verify",
          data: { otp: this.form.otp },
          method: "POST",
          messages: {
            500: true,
            422: (error) => {
              if (error.code === "E103") {
                return this.$t("error_occured_contact");
              } else {
                return this.$t(message);
              }
            },
          },
          notification: {
            right: false,
            centered: true,
          },
        });
        await this.$auth.fetch();

        // redirect
        this.$router.push(
          sessionRedirectPath ? sessionRedirectPath : { name: "dashboard" }
        );

        sessionStorage.removeItem("_redirect_path");
      } catch (error) {
        // eempty
      }
      this.loading.verify = false;
    },
    async resendOtp() {
      this.loading.resend = true;
      try {
        this.request({
          url: "/api/auth/register-resend-otp",
          method: "POST",
          messages: {
            500: true,
            200: this.$t("security.resend_otp.success", {
              email: this.$utils.censorEmail(this.$auth.user().email),
            }),
          },
        });
      } catch (error) {
        // empty
      }
      this.loading.resend = false;
    },
    ...mapActions({ notify: "notification/notify", request: "request" }),
  },
  computed: {
    otpErrors() {
      const errors = [];

      if (!this.$v.form.otp.$dirty) return errors;

      !this.$v.form.otp.required &&
        errors.push(this.$t("security.fields.otp.required"));
      !this.$v.form.otp.minLength &&
        errors.push(this.$t("security.fields.otp.invalid"));
      !this.$v.form.otp.matchOtp &&
        errors.push(this.$t("security.fields.otp.invalid"));
      return errors;
    },
  },
  components: {
    Lang,
    Copyright
  },
};
</script>