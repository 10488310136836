<template>
  <register-verify></register-verify>
</template>

<script>
import Vue from 'vue';
import RegisterVerify from './../../components/security/register-verify';
export default {
  beforeRouteEnter (to, from, next) {
    const user = Vue.prototype.$auth.user();
    if (user.active) {
      next({ name: "dashboard" });
    } else {
      next();
    }
  },
  data: () => ({}),
  components: {
    RegisterVerify
  }
}
</script>